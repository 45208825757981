<template lang="pug">
  .course-day__location-item
    .meeting_space(v-if="meetingSpace")
      locationIcon(:locationType="'location'")
      v-tooltip(top)
        template(v-slot:activator="{ on }")
          div(v-on="on")
            p {{ meetingSpace.name }}
        p {{ meetingSpace.name }}
    .meeting_space(v-else)
      locationIcon(:locationType="'no-location'")
      p No meeting space
</template>

<script>
export default {
  name: 'CourseDayLocationInfo',

  props: {
    meetingSpace: [Object, null],
    type: String
  },

  components: {
    locationIcon: () => import('@/components/global/LocationIcon.vue')
  }
}
</script>

<style lang="scss">
.course-day__location-item {
  .meeting_space {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    p {
      margin: 0 0 0 5px;
      padding: 0;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
</style>
